import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from '@/redux/store';
import { DateTime } from 'luxon';
import {
  getPatientStatisticsPageLoadingStatus,
  getSpecialistPatientWorkflowStatisticsPerYear,
  getStatisticsPageElementSpecialistPatientWorkflowPerYear
} from '@/redux/slices/app';
import useLocales from '@/hooks/useLocales';

// ----------------------------------------------------------------------

export default function SpecialistPatientsWorkflowPerYearStats() {
  const dispatch = useDispatch();
  const { translate } = useLocales('patient');

  const { patientWorkflowStepCBList } = useSelector((state) => state.patient);
  const { specialistPatientWorkflowPerYearStatistics } = useSelector((state) => state.app);
  const patientStatisticsPageLoadingStatus = useSelector((state) => getPatientStatisticsPageLoadingStatus(state));
  const loadingStatus = useSelector((state) => getStatisticsPageElementSpecialistPatientWorkflowPerYear(state));

  const [nbItemsDisplayedSeries, setNbItemsDisplayedSeries] = useState([10]);
  const [nbItemsDisplayedSeriesSelected, SetNbItemsDisplayedSeriesSelected] = useState(10);
  const [graphHeight, setGraphHeight] = useState(350);

  const [yearSeries] = useState([2023, 2024, 2025, 2026, 2027, 2028]);
  const [yearSelected, setYearSelected] = useState(DateTime.now().year);

  const [graphState, setGraphState] = useState({
    options: {
      chart: {
        type: 'bar',
        stacked: false,
        toolbar: {
          show: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '70%'
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['', '', '', '', '', '', '', '', '', ''],
        title: {
          text: translate('charts.specialistPatientWorkflowPerYearStatistics.xaxis.label')
        }
      },
      yaxis: {
        title: {
          text: translate('charts.specialistPatientWorkflowPerYearStatistics.yaxis.label')
        }
      },
      title: {
        text: translate('charts.specialistPatientWorkflowPerYearStatistics.title'),
        align: 'center'
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center'
      },
      colors: [] // Muted grayscale colors
    },
    series: []
  });

  useEffect(() => {
    dispatch(getSpecialistPatientWorkflowStatisticsPerYear(yearSelected));
  }, [dispatch, yearSelected]);

  useEffect(() => {
    if (loadingStatus.loadingStatus === 'loading') {
      // Display placeholder data during loading
      const seriesEmptyState = Array.from({ length: nbItemsDisplayedSeriesSelected }, () => 0);
      const emptyCategories = Array.from({ length: nbItemsDisplayedSeriesSelected }, () => 'Loading...');

      setGraphState((prev) => ({
        ...prev,
        options: {
          ...prev.options,
          xaxis: { ...prev.options.xaxis, categories: emptyCategories }
        },
        series: [{ data: seriesEmptyState }]
      }));
    }

    if (
      patientStatisticsPageLoadingStatus.loadingStatus === 'succeeded' &&
      loadingStatus.loadingStatus === 'succeeded'
    ) {
      const step = 10;
      const slicedStats = specialistPatientWorkflowPerYearStatistics.slice(0, nbItemsDisplayedSeriesSelected);
      const nbSeries = Math.ceil(specialistPatientWorkflowPerYearStatistics.length / step);

      let series = [];

      // Iterate over patientWorkflowStepCBList to create series data
      patientWorkflowStepCBList.forEach((wflStep) => {
        const seriesArray = slicedStats.map((stat) => {
          const { workflowSteps } = stat;
          const foundStep = workflowSteps.find((statWflStep) => statWflStep._id === wflStep._id);

          // Push total patients if found, otherwise 0
          return foundStep ? foundStep.totalPatients : 0;
        });

        // Push the series object for the current workflow step
        series.push({
          name: wflStep.label,
          data: seriesArray
        });
      });

      setNbItemsDisplayedSeries(
        Array(nbSeries)
          .fill(0)
          .map((_, idx) => (idx + 1) * step)
      );

      setGraphHeight(slicedStats.length ? `${88 * slicedStats.length}px` : '100%');
      const listOfCategories = slicedStats.map((sStat) => `${sStat.firstName} ${sStat.lastName}`);

      setGraphState({
        options: {
          ...graphState.options,
          colors: patientWorkflowStepCBList.map((wflStep) => wflStep.color),
          xaxis: {
            ...graphState.options.xaxis,
            categories: listOfCategories
          }
        },
        series: [...series]
      });
    }
  }, [
    patientStatisticsPageLoadingStatus,
    loadingStatus,
    specialistPatientWorkflowPerYearStatistics,
    nbItemsDisplayedSeriesSelected,
    patientWorkflowStepCBList
  ]);

  const handleChangeYear = (event) => {
    setYearSelected(parseInt(event.target.value, 10));
  };

  const handleChangeNbItemsDisplayedSelected = (event) => {
    SetNbItemsDisplayedSeriesSelected(parseInt(event.target.value, 10));
  };

  const textFieldStyles = {
    '& fieldset': { border: '0 !important' },
    '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
    '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
    '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
  };

  const cardHeaderFilterContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '4px 12px'
  };

  return (
    <Card>
      <CardHeader
        title="Patients par Spécialiste et Type de Processus"
        subheader="Année 2024"
        action={
          <Box sx={cardHeaderFilterContainerStyles}>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {translate('common:filters.nbItemsDisplayed', { year: yearSelected })}:
            </Typography>
            <TextField
              select
              value={nbItemsDisplayedSeriesSelected}
              onChange={handleChangeNbItemsDisplayedSelected}
              SelectProps={{ native: true }}
              disabled={
                patientStatisticsPageLoadingStatus.loadingStatus !== 'succeeded' &&
                loadingStatus.loadingStatus !== 'succeeded'
              }
              sx={textFieldStyles}
            >
              {nbItemsDisplayedSeries.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {translate('common:filters.yearFilter', { year: yearSelected })}:
            </Typography>
            <TextField
              select
              value={yearSelected}
              onChange={handleChangeYear}
              SelectProps={{ native: true }}
              disabled={
                patientStatisticsPageLoadingStatus.loadingStatus !== 'succeeded' &&
                loadingStatus.loadingStatus !== 'succeeded'
              }
              sx={textFieldStyles}
            >
              {yearSeries.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </TextField>
          </Box>
        }
      />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <ReactApexChart options={graphState.options} series={graphState.series} type="bar" height={graphHeight} />
        {/*<ReactApexChart options={} series={graphState.series} type="bar" height={350} />*/}
      </Box>
    </Card>
  );
}
