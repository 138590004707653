import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from '@/redux/store';
import { getNewPatientPerMonthStatisticsLoadingStatus, getPatientsPerMonthStatistics } from '@/redux/slices/app';
import { DateTime } from 'luxon';
import useLocales from '@/hooks/useLocales';

// ----------------------------------------------------------------------

export default function PatientCreationByMonthsStats() {
  const dispatch = useDispatch();
  const { translate } = useLocales('patient');
  const { newPatientPerMonthStatistics } = useSelector((state) => state.app);
  const loadingStatus = useSelector((state) => getNewPatientPerMonthStatisticsLoadingStatus(state));

  const [yearSeries] = useState([2023, 2024, 2025, 2026, 2027, 2028]);
  const [yearSelected, setYearSelected] = useState(DateTime.now().year);

  const [graphState, setGraphState] = useState({
    options: {
      tooltip: {
        marker: { show: false },
        y: { title: { formatter: () => '' } }
      },
      chart: { id: 'apexchart-example' },
      xaxis: {
        title: { text: 'Mois' },
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yaxis: {
        forceNiceScale: true,
        title: { text: 'Patient' }
      }
    },
    series: [{ data: [] }]
  });

  useEffect(() => {
    dispatch(getPatientsPerMonthStatistics(yearSelected));
  }, [dispatch, yearSelected]);

  useEffect(() => {
    if (loadingStatus.loadingStatus === 'loading') {
      const seriesEmptyState = Array.from({ length: 12 }, () => 0); // Reset graph data during loading
      setGraphState((prev) => ({
        ...prev,
        series: [{ data: seriesEmptyState }]
      }));
    }

    if (loadingStatus.loadingStatus === 'succeeded') {
      setGraphState((prev) => ({
        ...prev,
        series: [{ data: newPatientPerMonthStatistics.map((stat) => stat.total) }]
      }));
    }
  }, [loadingStatus, newPatientPerMonthStatistics]);

  const handleChangeYear = (event) => {
    const selectedYear = parseInt(event.target.value, 10);
    setYearSelected(selectedYear);
  };

  const textFieldStyles = {
    '& fieldset': { border: '0 !important' },
    '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
    '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
    '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
  };

  const cardHeaderFilterContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '4px 12px'
  };

  return (
    <Card>
      <CardHeader
        title="Nouveaux patients par mois"
        subheader={`Année ${yearSelected}`}
        action={
          <Box sx={cardHeaderFilterContainerStyles}>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {translate('common:filters.yearFilter', { year: yearSelected })}:
            </Typography>
            <TextField
              select
              value={yearSelected}
              onChange={handleChangeYear}
              SelectProps={{ native: true }}
              disabled={loadingStatus.loadingStatus !== 'succeeded'}
              sx={textFieldStyles}
            >
              {yearSeries.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </TextField>
          </Box>
        }
      />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <ReactApexChart options={graphState.options} series={graphState.series} type="bar" height={350} />
      </Box>
    </Card>
  );
}
