// material
import { Container, Grid } from '@mui/material';
// redux
import { useDispatch } from '@/redux/store';
// routes
import { PATH_DASHBOARD } from '@/routes/paths';
// hooks
import useLocales from '@/hooks/useLocales';
// components
import Page from '@/components/Page';
import HeaderBreadcrumbs from '@/components/HeaderBreadcrumbs';
import PatientCreationByMonthsStats from '@/modules/patient/components/statistics/PatientCreationByMonthsStats';
import { useEffect } from 'react';
import { getPatientsStatisticsPage } from '@/redux/slices/app';
import { DateTime } from 'luxon';
import SpecialistReferringPerYearStats from '@/modules/patient/components/statistics/SpecialistReferringPerYearStats';
import SpecialistPatientsWorkflowPerYearStats from '@/modules/patient/components/statistics/SpecialistPatientsWorkflowPerYearStats';

PatientStatistics.propTypes = {};

export default function PatientStatistics() {
  const dispatch = useDispatch();
  const { translate } = useLocales('patient');

  useEffect(() => {
    let currentDt = DateTime.now();
    dispatch(getPatientsStatisticsPage(currentDt.year));
  }, [dispatch]);

  return (
    <Page title={'Statistiques' /*translate('patientList.pageTitle')*/}>
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading={'Statistiques' /*translate('patientList.title')*/}
          links={[
            { name: translate('patientList.breadCrumbDashboardLabel'), href: PATH_DASHBOARD.root },
            { name: 'Patient', href: null },
            { name: 'Statistiques' /*translate('patientList.breadCrumbListLabel')*/ }
          ]}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <SpecialistPatientsWorkflowPerYearStats />
          </Grid>

          <Grid item xs={12} md={12}>
            <SpecialistReferringPerYearStats />
          </Grid>

          <Grid item xs={12} md={12}>
            <PatientCreationByMonthsStats />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
