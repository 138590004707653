import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from '@/redux/store';
import {
  getSpecialistReferringPerYearStatistics,
  getStatisticsPageElementSpecialistRefPerYear
} from '@/redux/slices/app';
import { DateTime } from 'luxon';
import useLocales from '@/hooks/useLocales';

// ----------------------------------------------------------------------

export default function SpecialistReferringPerYearStats() {
  const dispatch = useDispatch();
  const { translate } = useLocales('patient');
  const { specialistReferringPerYearStatistics } = useSelector((state) => state.app);
  const loadingStatus = useSelector((state) => getStatisticsPageElementSpecialistRefPerYear(state));

  const [nbItemsDisplayedSeries, setNbItemsDisplayedSeries] = useState([10]);
  const [nbItemsDisplayedSeriesSelected, SetNbItemsDisplayedSeriesSelected] = useState(10);
  const [graphHeight, setGraphHeight] = useState(350);

  const [yearSeries] = useState([2023, 2024, 2025, 2026, 2027, 2028]);
  const [yearSelected, setYearSelected] = useState(DateTime.now().year);

  const [graphState, setGraphState] = useState({
    options: {
      tooltip: {
        marker: { show: false },
        y: { title: { formatter: () => '' } }
      },
      plotOptions: {
        bar: { horizontal: true, barHeight: '60%', columnWidth: '100%', borderRadius: 2 }
      },
      xaxis: {
        title: { text: 'Patient' },
        forceNiceScale: true,
        labels: { formatter: (val) => val },
        categories: Array.from({ length: 10 }, () => '') // Placeholder categories
      },
      yaxis: { forceNiceScale: true }
    },
    series: [{ data: Array.from({ length: 10 }, () => 0) }] // Placeholder data
  });

  useEffect(() => {
    dispatch(getSpecialistReferringPerYearStatistics(yearSelected));
  }, [dispatch, yearSelected]);

  useEffect(() => {
    if (loadingStatus.loadingStatus === 'loading') {
      // Display placeholder data during loading
      const seriesEmptyState = Array.from({ length: nbItemsDisplayedSeriesSelected }, () => 0);
      const emptyCategories = Array.from({ length: nbItemsDisplayedSeriesSelected }, () => 'Loading...');

      setGraphState((prev) => ({
        ...prev,
        options: {
          ...prev.options,
          xaxis: { ...prev.options.xaxis, categories: emptyCategories }
        },
        series: [{ data: seriesEmptyState }]
      }));
    }

    if (loadingStatus.loadingStatus === 'succeeded') {
      const step = 10;
      const slicedStats = specialistReferringPerYearStatistics.slice(0, nbItemsDisplayedSeriesSelected);
      const nbSeries = Math.ceil(specialistReferringPerYearStatistics.length / step);

      setNbItemsDisplayedSeries(Array.from({ length: nbSeries }, (_, idx) => (idx + 1) * step));
      setGraphHeight(slicedStats.length ? `${32 * slicedStats.length}px` : '100%');

      const listOfCategories = slicedStats.map((sStat) => `${sStat.firstName} ${sStat.lastName}`);
      const listOfData = slicedStats.map((sStat) => sStat.total);

      setGraphState((prev) => ({
        ...prev,
        options: {
          ...prev.options,
          xaxis: { ...prev.options.xaxis, categories: listOfCategories }
        },
        series: [{ data: listOfData }]
      }));
    }
  }, [loadingStatus, specialistReferringPerYearStatistics, nbItemsDisplayedSeriesSelected]);

  const handleChangeYear = (event) => {
    setYearSelected(parseInt(event.target.value, 10));
  };

  const handleChangeNbItemsDisplayedSelected = (event) => {
    SetNbItemsDisplayedSeriesSelected(parseInt(event.target.value, 10));
  };

  const textFieldStyles = {
    '& fieldset': { border: '0 !important' },
    '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
    '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
    '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
  };

  const cardHeaderFilterContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '4px 12px'
  };

  return (
    <Card>
      <CardHeader
        title="Nombre de patients référés par spécialiste"
        subheader={translate('common:labels.yearSelected', { year: yearSelected })}
        action={
          <Box sx={cardHeaderFilterContainerStyles}>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {translate('common:filters.nbItemsDisplayed', { year: yearSelected })}:
            </Typography>
            <TextField
              select
              value={nbItemsDisplayedSeriesSelected}
              onChange={handleChangeNbItemsDisplayedSelected}
              SelectProps={{ native: true }}
              disabled={loadingStatus.loadingStatus !== 'succeeded'}
              sx={textFieldStyles}
            >
              {nbItemsDisplayedSeries.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {translate('common:filters.yearFilter', { year: yearSelected })}:
            </Typography>
            <TextField
              select
              value={yearSelected}
              onChange={handleChangeYear}
              SelectProps={{ native: true }}
              disabled={loadingStatus.loadingStatus !== 'succeeded'}
              sx={textFieldStyles}
            >
              {yearSeries.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </TextField>
          </Box>
        }
      />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <ReactApexChart options={graphState.options} series={graphState.series} type="bar" height={graphHeight} />
      </Box>
    </Card>
  );
}
