import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';
import { appApi } from '@/_apis_/appApi';
import { getPatientWorkflowStepList, getPatientWorkflowTemplateDocumentList } from '@/redux/slices/patient';
// utils
// configs
// ----------------------------------------------------------------------

const stateName = 'app';

const initialState = {
  isLoading: false,
  error: false,
  dataLoaded: false,
  newPatientPerMonthStatistics: [],
  specialistReferringPerYearStatistics: [],
  specialistPatientWorkflowPerYearStatistics: [],
  pages: {
    ['statistics']: {
      loadingStatus: 'idle',
      loadingFailedReason: null,
      elements: {
        ['newPatientPerMonthStatistics']: {
          loadingStatus: 'idle',
          loadingFailedReason: null
        },
        ['specialistReferringPerYearStatistics']: {
          loadingStatus: 'idle',
          loadingFailedReason: null
        },
        ['specialistPatientWorkflowPerYearStatistics']: {
          loadingStatus: 'idle',
          loadingFailedReason: null
        }
      }
    }
  }
};

const slice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DASHBOARD SETTINGS
    appPageDataLoaded(state) {
      state.dataLoaded = true;
    },

    // GET USER SPECIALIST PATIENT WORKFLOW STEP STATISTICS PER YEAR
    specialistPatientWorkflowStatisticsPerYearIsLoading(state) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        elements: {
          ...state.pages['statistics'].elements,
          specialistPatientWorkflowPerYearStatistics: {
            loadingStatus: 'loading',
            loadingFailedReason: null
          }
        }
      };
    },

    specialistPatientWorkflowStatisticsPerYearLoaded(state) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        elements: {
          ...state.pages['statistics'].elements,
          specialistPatientWorkflowPerYearStatistics: {
            loadingStatus: 'succeeded',
            loadingFailedReason: null
          }
        }
      };
    },

    specialistPatientWorkflowStatisticsPerYearFailed(state, action) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        elements: {
          ...state.pages['statistics'].elements,
          specialistPatientWorkflowPerYearStatistics: {
            loadingStatus: 'failed',
            loadingFailedReason: action.payload
          }
        }
      };
    },

    getSpecialistPatientWorkflowStatisticsPerYearSuccess(state, action) {
      state.isLoading = false;
      state.specialistPatientWorkflowPerYearStatistics = action.payload;
    },

    // GET USER SPECIALIST REF PATIENT STATISTICS PER YEAR
    specialistReferringPerYearStatisticsIsLoading(state) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        elements: {
          ...state.pages['statistics'].elements,
          specialistReferringPerYearStatistics: {
            loadingStatus: 'loading',
            loadingFailedReason: null
          }
        }
      };
    },

    specialistReferringPerYearStatisticsLoaded(state) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        elements: {
          ...state.pages['statistics'].elements,
          specialistReferringPerYearStatistics: {
            loadingStatus: 'succeeded',
            loadingFailedReason: null
          }
        }
      };
    },

    specialistReferringPerYearStatisticsFailed(state, action) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        elements: {
          ...state.pages['statistics'].elements,
          specialistReferringPerYearStatistics: {
            loadingStatus: 'failed',
            loadingFailedReason: action.payload
          }
        }
      };
    },

    getSpecialistReferringPerYearStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.specialistReferringPerYearStatistics = action.payload;
    },

    // GET PATIENT STATISTICS PER MONTH
    newPatientPerMonthStatisticsIsLoading(state) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        elements: {
          ...state.pages['statistics'].elements,
          newPatientPerMonthStatistics: {
            loadingStatus: 'loading',
            loadingFailedReason: null
          }
        }
      };
    },

    newPatientPerMonthStatisticsLoaded(state) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        elements: {
          ...state.pages['statistics'].elements,
          newPatientPerMonthStatistics: {
            loadingStatus: 'succeeded',
            loadingFailedReason: null
          }
        }
      };
    },

    newPatientPerMonthStatisticsFailed(state, action) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        elements: {
          ...state.pages['statistics'].elements,
          newPatientPerMonthStatistics: {
            loadingStatus: 'failed',
            loadingFailedReason: action.payload
          }
        }
      };
    },

    getPatientsPerMonthStatisticsSuccess(state, action) {
      state.newPatientPerMonthStatistics = action.payload;
    },

    // GET PATIENT STATISTICS PAGE
    patientStatisticsPageIsLoading(state) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        loadingStatus: 'loading'
      };
    },

    patientStatisticsPageLoaded(state) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        loadingStatus: 'succeeded'
      };
    },

    patientStatisticsPageFailed(state, action) {
      state.pages['statistics'] = {
        ...state.pages['statistics'],
        loadingStatus: 'failed',
        loadingFailedReason: action.payload
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export const getPatientsStatisticsPage = () => async (dispatch) => {
  dispatch(slice.actions.patientStatisticsPageIsLoading());
  await Promise.all([dispatch(getPatientWorkflowStepList())]);
  return dispatch(slice.actions.patientStatisticsPageLoaded());
};

export function getPatientsPerMonthStatistics(year) {
  return async (dispatch) => {
    dispatch(slice.actions.newPatientPerMonthStatisticsIsLoading());
    try {
      const { newPatientsPerMonth } = await appApi.getPatientsPerMonthStatistics(year);
      const newPatientsPerMonthMap = Array.from({ length: 12 }, (_, i) => i + 1).map((monthNumber) => {
        const monthData = newPatientsPerMonth.find((stat) => stat.month === monthNumber);
        return monthData != null ? monthData : { total: 0, month: monthNumber, year: year };
      });
      dispatch(slice.actions.getPatientsPerMonthStatisticsSuccess(newPatientsPerMonthMap));

      dispatch(slice.actions.newPatientPerMonthStatisticsLoaded());
    } catch (error) {
      dispatch(slice.actions.newPatientPerMonthStatisticsFailed(error));
    }
  };
}

export function getSpecialistReferringPerYearStatistics(year) {
  return async (dispatch) => {
    dispatch(slice.actions.specialistReferringPerYearStatisticsIsLoading());
    try {
      const { specialistReferringPatientPerYear } = await appApi.getSpecialistReferringPerYearStatistics(year);
      dispatch(slice.actions.getSpecialistReferringPerYearStatisticsSuccess(specialistReferringPatientPerYear));

      dispatch(slice.actions.specialistReferringPerYearStatisticsLoaded());
    } catch (error) {
      dispatch(slice.actions.specialistReferringPerYearStatisticsFailed(error));
    }
  };
}

export function getSpecialistPatientWorkflowStatisticsPerYear(year) {
  return async (dispatch) => {
    dispatch(slice.actions.specialistPatientWorkflowStatisticsPerYearIsLoading());
    try {
      const { patientWorkflowStatisticsPerYear } = await appApi.getSpecialistPatientWorkflowStatisticsPerYear(year);
      dispatch(slice.actions.getSpecialistPatientWorkflowStatisticsPerYearSuccess(patientWorkflowStatisticsPerYear));

      dispatch(slice.actions.specialistPatientWorkflowStatisticsPerYearLoaded());
    } catch (error) {
      dispatch(slice.actions.specialistPatientWorkflowStatisticsPerYearFailed(error));
    }
  };
}

export const getAppData = () => async (dispatch) => {
  await Promise.all([dispatch(getPatientWorkflowStepList()), dispatch(getPatientWorkflowTemplateDocumentList())]);
  return dispatch(slice.actions.appPageDataLoaded());
};

// #region Selectors
const selectSelf = (state) => state;
const getAppState = createDraftSafeSelector(selectSelf, (state) => state[stateName]);

export const getPatientStatisticsPage = createDraftSafeSelector(getAppState, (state) => state.pages['statistics']);

export const getStatisticsPageElementNewPatientsPerMonth = createDraftSafeSelector(
  getPatientStatisticsPage,
  (state) => state.elements['newPatientPerMonthStatistics']
);

export const getStatisticsPageElementSpecialistRefPerYear = createDraftSafeSelector(
  getPatientStatisticsPage,
  (state) => state.elements['specialistReferringPerYearStatistics']
);

export const getStatisticsPageElementSpecialistPatientWorkflowPerYear = createDraftSafeSelector(
  getPatientStatisticsPage,
  (state) => state.elements['specialistPatientWorkflowPerYearStatistics']
);

export const getPatientStatisticsPageLoadingStatus = createDraftSafeSelector(getPatientStatisticsPage, (state) => ({
  loadingStatus: state.loadingStatus,
  loadingFailedReason: state.loadingFailedReason
}));

export const getNewPatientPerMonthStatisticsLoadingStatus = createDraftSafeSelector(
  getStatisticsPageElementNewPatientsPerMonth,
  (state) => ({
    loadingStatus: state.loadingStatus,
    loadingFailedReason: state.loadingFailedReason
  })
);

// #endregion
