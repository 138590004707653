import { apiConfig } from '@/config';
import axiosWithAuth from '@/utils/axiosAuthenticated';

class AppApi {
  // #region GET Methods

  async getPatientsPerMonthStatistics(year) {
    return axiosWithAuth
      .get(`${apiConfig.apiUrl}/patient/statistics`, { params: { year } })
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async getSpecialistReferringPerYearStatistics(year) {
    return axiosWithAuth
      .get(`${apiConfig.apiUrl}/user-specialist/statistics`, { params: { year } })
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async getSpecialistPatientWorkflowStatisticsPerYear(year) {
    return axiosWithAuth
      .get(`${apiConfig.apiUrl}/user-specialist/patient/workflow/statistics`, { params: { year } })
      .then(({ data: { data } }) => {
        return data;
      });
  }

  // #endregion
  // #region POST Methods
  // #endregion
  // #region PUT Methods
  // #endregion
  // #region DELETE Methods
  // #endregion
}

export const appApi = new AppApi();
